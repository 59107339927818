
import React from "react";
import MyDecksPageContainer from '../../../Dashboard/myDecks/containers/MyDecksPageContainer';


const myDecksPage = ({ location }) => {

  return (
    <MyDecksPageContainer location={location}/>
  )
}

export default myDecksPage;
