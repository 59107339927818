
import React, { useEffect } from "react";
import useSetState from '../../../utils/useSetState';
import fetch_data from './utils/fetch_data';
import removeDeck from './utils/removeDeck';
import MyDecks from '../MyDecks';
import queryString from 'query-string';
import Navbar from "../../../lib/components/Navbar/Navbar";
import Footer from "../../../lib/components/Footer/Footer";
import CenterContainer from "../../../lib/components/CenterContainer/CenterContainer";
import DashboardWrapper from "../../DashboardWrapper";



const MyDecksPageContainer = ({ location }) => {

  const [state, setState] = useSetState({})

  const artistId = queryString.parse(location.search).artistId;

  useEffect(() => {      
    fetch_data({ setState, artistId });
  }, [])

  return (
    <>
      <Navbar/>
      <CenterContainer>
        <DashboardWrapper>
          <MyDecks data={state} removeDeck={removeDeck.bind(null, state, setState)}/>
        </DashboardWrapper>
      </CenterContainer>
      <br/>
      <br/>
      <br/>
     
    </>
  );
};

export default MyDecksPageContainer;
