import React from 'react';
import { Link } from 'gatsby';
import GridSkeletons from '../../lib/components/GridSkeletons/GridSkeletons';
import CardHorizontal from '../../lib/components/CardHorizontal/CardHorizontal';
import Breadcrumbs from '../../lib/components/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../lib/components/PageHeader/PageHeader';
import Button from '../../lib/components/Button/Button';
import stringifyTimestamp from '../../utils/stringifyTimestamp';
import { Helmet } from 'react-helmet';

const MyDecks = ({ data, removeDeck }) => {
  const { decks } = data;

  return (
    <>
      <Helmet>
        <title>My Decks</title>
      </Helmet>
      <PageHeader>
        <h1 className="breadCrumbTitle">My Decks</h1>
        <Link to="/dashboard/myDecks/create">
          <Button>Create</Button>
        </Link>
      </PageHeader>
      {decks ? (
        decks.map((deck) => {
          const description = `${stringifyTimestamp({
            timestamp: deck.createdAt
          })} | ${(deck.deckItems || []).length} items`;

          return (
            <CardHorizontal
              key={deck._id}
              title={deck.title}
              imgUrl={deck.coverImgUrl}
              cardLink={'/deck?_id=' + deck._id}
              description={description}
            >
              <Button onClick={removeDeck.bind(null, deck)}>Trash</Button>
              <Link to={`/dashboard/myDecks/update?deckId=${deck._id}`}>
                <Button>Update</Button>
              </Link>
            </CardHorizontal>
          );
        })
      ) : (
        <GridSkeletons />
      )}
    </>
  );
};

export default MyDecks;
