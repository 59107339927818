
import { api_base_url } from '../../../../../global_data';
import axios from "axios";
import { cloneDeep } from "lodash";


const removeDeck = async (state, setState, deck) => {
    

    if(window.confirm(`This action is irreversible. Are you sure you want to remove the deck: ${deck.title}`)) {

        await axios({
            url: `${api_base_url}/decks/${deck._id}`,
            method: 'DELETE'
        });

        const decksClone = cloneDeep(state.decks);

        const deckToRemoveId = decksClone.findIndex((ele) => {
            return ele._id == deck._id;
        });
        
        decksClone.splice(deckToRemoveId, 1);

        setState({decks: decksClone});

    }

    else {
        //
    }
    

}

export default removeDeck;