
import React from "react";
import { Link } from "gatsby";
import styles from './CardHorizontal.module.css';

const CardHorizontal = ({ title, imgUrl, description, cardLink, children }) => {
    return (
      <div className={styles.Card}>
        <Link to={cardLink}>
          <div 
              style={{backgroundImage: `url("${imgUrl}")`}} 
              className={styles.card__thumb}
            >
            {/* Empty */}
          </div>
        </Link>
        <div className={styles.card__info}>
            <div>
              <div className={styles.card__info__title}>{title}</div>
              <div className={styles.card__info__description}>
                  {description}
              </div>
            </div>
            <div className={styles.buttonsBox}>
              <div>
                { children }
              </div>
            </div>
        </div>
        
      </div>
    )
  }


  export default CardHorizontal;
