
import { api_base_url } from '../../../../../global_data';
import axios from "axios";


const fetch_data = async ({ setState, artistId }) => {
    
    const res = await axios({
        url: `${api_base_url}/decks/filter?filterBy=artist&filterVal=${artistId}`,
        method: 'GET'
    });

    const data = res.data;
    setState({decks: data});

}

export default fetch_data;